import React, { useState, useCallback, useEffect, useMemo } from "react";
import Collapse from "@kunukn/react-collapse";
import FilterIcon from "../../assets/svg/inline/ic_filter.svg"
import IconSortAsc from "../../assets/svg/inline/ic_sort_asc.svg"
import IconSortDesc from "../../assets/svg/inline/ic_sort_desc.svg"
import ChevronIcon from "../../assets/svg/inline/ic_chevron-down.svg"
import RadioGroup from "./RadioGroup"
import { getMaxLTV } from './Product';
import DownloadIcon from "../../assets/svg/inline/ic_download.svg"
import Select from 'react-select'

export default function Filter({initialFilters, products, setFilteredProducts, location, resetPagination, numMatches, setNumMatches, hideFilter}) {
	
  const [filterOpen, setFilterOpen] = useState(false);
  const toggleFilter     		    = () => setFilterOpen(previousState => !previousState);
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const toggleAdvancedFilter     	= () => setAdvancedFilterOpen(previousState => !previousState);
  //const [numMatches, setNumMatches] = useState(products.length);

  //for controlling form elements
  //const defaultFormValues = {search:'', productRange:'',propertyType:'',productType:'',ltv:'', sortBy:'rate',sortReverse:false,loanType:'',exLocalAuth:'',newBuild:'',adjCommercial:''}
  const defaultFormValues = useMemo(() => {
    return {search:'', productRange:'',propertyType:'',productType:'',ltv:'', sortBy:'rate',sortReverse:false,loanType:'',exLocalAuth:'',newBuild:'',adjCommercial:''}
  }, [])

  const [formValues, setFormValues] = useState(defaultFormValues);

  const sortByOptions = [
	{value:'rate', label:'Rate'}, 
	{value:'propertytype', label:'Property type'}, 
	{value:'producttype', label:'Product type'}, 
	{value:'maxLtv', label:'Max LTV'}, 
	{value:'productFeePerc', label:'Product fee'}, 
	{value:'minLoan', label:'Min loan'}, 
	{value:'maxLoan', label:'Max loan'} 
  ];
  const loanTypeOptions = ['Purchase','Remortgage'];

  /**
   * Gets the html markup for a list of pulldown options passed as an array
   */
  const makePulldownFromArray = useCallback((values) => {
	    
	  //get unique items
	  let uniqueValues = [...new Set(values)],
	  	  options = [];

	  //sort
	  uniqueValues.sort((a, b) => a.localeCompare(b));

	  uniqueValues.forEach(function(value) {
		options.push({ value, label: value })
	  });

	  return options;
	    
  }, []);

  /**
   * Gets the unique list of property type options
   */
  const getPropertyTypeOptions = useCallback(() => {
	    
	  var values = [];
	  products.forEach(function(product) {
		product.propertyCategories?.forEach(function(category) {
		 values.push(category);
		})
	  })

	  return values;
	    
  }, [products]);

  /**
   * Gets the uniqe list of product loan to value options
   */
  const getLTVOptions = useCallback(() => {
	    
	  var values = [];
	  products.forEach(function(product) {
		values.push(getMaxLTV(product))
	  });

	  return values;
	    
  }, [products]);

  /**
   * Gets the unique list of product range options for a specified field
   */
  const getProductOptions = useCallback((field) => {
	    
	  var values = [];
	  products.forEach(function(product) {
		if (product[field]) {
			values.push(product[field])
		}
	  });

	  return values;
	    
  }, [products]);

  /**
   * Called when a form field changes
   */
  const handleChange = function(event) {

    let newValues = { ...formValues };

	newValues[event.target.name] = event.target.value;
	
	setFormValues(newValues);
  }

  /**
   * Called when a react select pulldown changes
   */
  const handleSelectChange = function(target, value) {

    let newValues = { ...formValues };

	newValues[target] = value;
	
	setFormValues(newValues);
  }

  const getSelectValue = function(target) {
	let value = formValues[target];
	if (target==='sortBy') {
		return sortByOptions.find(obj => obj.value===value);
	} else if (value!=='') {
		return {value, label: value};
	}
	return null;
  }

  /*
   * When the filter is submitted
   */
  const handleSubmit = function(event) {
    event.preventDefault();
  }

  /**
   * when the clear form button is clicked
   */
  const clearForm = function() {
	//we want to retain the sort value
	let newValues = { ...defaultFormValues };
	newValues.sortBy = formValues.sortBy;
    setFormValues(newValues);
	resetPagination();
  }

  const updateFilterInAddressBar = useCallback((filter) => {
	//check for browser support for URLSearchParams
	if (!hideFilter && window && 'URLSearchParams' in window) {
		
	  const params = new URLSearchParams(window.location.search);
		
  	  //loop through the filter values
	  for (let [key, value] of Object.entries(filter)) {
	  	//console.log(`${key}: ${value}`);
		//add it if it has a value
		if (value && value!==defaultFormValues[key]) {		
			if (typeof value === 'string') {
				value = value.replace('%','');
			}	
			params.set(key, value);
		} else {
			//remove it in case it's already in the url
			params.delete(key);
		}
	  }
	  
	  let url = window.location.pathname;
	  if (params.toString().length) {
		url += '?'+params;
	  }

	  //update the url
	  window.history.pushState({}, '', url);
	}
  }, [hideFilter, defaultFormValues]);

  /**
   * when the form is submitted via the submit button
   */
  const submitForm = useCallback((filter) => {
	
    let filteredProducts = [];
	products.forEach(function(product) {
	  let includeThisItem = true;

	  //search
	  if (filter.search!=='' 
		  && product.productName.toUpperCase().indexOf(filter.search.toUpperCase())===-1 
		  && product.productCode.toUpperCase().indexOf(filter.search.toUpperCase())===-1) {
		includeThisItem = false;
	  }

	  //productRange
	  if (includeThisItem && filter.productRange!=='' && product.productRange!==filter.productRange) {
		includeThisItem = false;
	  }
		
	  //propertytype
	  if (includeThisItem && filter.propertyType!=='' && !product.propertyCategories.includes(filter.propertyType)) {
		includeThisItem = false;
	  }		
		
	  //producttype
	  if (includeThisItem && filter.productType!=='' && product.productType!==filter.productType) {
		includeThisItem = false;
	  }	
		
	  //LTV
	  if (includeThisItem && filter.ltv!=='' && getMaxLTV(product)!==filter.ltv) {
		includeThisItem = false;
	  }
		
	  //loanType
	  if (includeThisItem && filter.loanType!=='' && !product.loanType?.includes(filter.loanType.toUpperCase())) {
		includeThisItem = false;
	  }
		  
	  //newBuild
	  if (includeThisItem && filter.newBuild!=='') {
		if (filter.newBuild==='Yes' && product.newBuild!=='NEW_BUILDS_ONLY' && product.newBuild!=='NEW_BUILDS_PERMITTED') {
		  includeThisItem = false;
		} else if (filter.newBuild==='No' && product.newBuild!=='NO_NEW_BUILDS' && product.newBuild!=='NEW_BUILDS_PERMITTED') {
		  includeThisItem = false;
		}
	  }
	  //exLocalAuth
	  if (includeThisItem && filter.exLocalAuth!=='') {
		if (filter.exLocalAuth==='Yes' && product.exLocalAuth!=='ALLOW_EX_LA' && product.exLocalAuth!=='ONLY_EX_LA') {
			includeThisItem = false;
		} else if (filter.exLocalAuth==='No' && product.exLocalAuth!=='ALLOW_EX_LA' && product.exLocalAuth!=='NO_EX_LA') {
			includeThisItem = false;
		}
	  }
	  //adjCommercial
	  if (includeThisItem && filter.adjCommercial!=='') {
		if (filter.adjCommercial==='Yes' && product.adjCommercial!=='ALLOW_ADJ_COMM' && product.adjCommercial!=='ONLY_ADJ_COMM') {
			includeThisItem = false;
		} else if (filter.adjCommercial==='No' && product.adjCommercial!=='ALLOW_ADJ_COMM' && product.adjCommercial!=='NO_ADJ_COMM') {
			includeThisItem = false;
		}
	  }
		
	  if (includeThisItem) {
	    filteredProducts.push(product);
	  }
	});
	
	//sortby
	if (filter.sortBy!=='') {
		if (filter.sortBy==='propertytype') {//string, special accessor
			filteredProducts.sort((a, b) => a.propertyCategories[0].localeCompare(b.propertyCategories[0]));
		} else if (filter.sortBy==='producttype') {//string
			filteredProducts.sort((a, b) => a['productType'].localeCompare(b['productType']));
		} else {//numeric
			filteredProducts.sort( (a,b) => a[filter.sortBy]-b[filter.sortBy] )
		}
	}
	
	//sortReverse
	if (filter.sortReverse) {
		filteredProducts = filteredProducts.reverse();
	}
	
	updateFilterInAddressBar(filter);
	
	//call the method in the parent to set the filtered list
	//console.log('number of matched items: ', filteredProducts.length)
	setNumMatches(filteredProducts.length);
	
	//setFilteredProducts(filteredProducts.slice(0, numItems));
	setFilteredProducts(filteredProducts);

  }, [products, setFilteredProducts, setNumMatches, updateFilterInAddressBar]);

  /**
   * when the direction button is clicked
   */
  const toggleSortReverse = function() {

    let newValues = { ...formValues };

	newValues.sortReverse = !formValues.sortReverse;
	
	setFormValues(newValues);
  }

  /*if we've just navigated from the product range widget we might want to select a product range*/
  useEffect(() => {
    if (typeof location?.state?.productRange != 'undefined' && formValues.productRange!==location.state.productRange) {	
      let newValues = { ...formValues };
	  newValues.productRange = location.state.productRange;	
	  setFormValues(newValues);
    }
  }, [location, formValues, setFormValues]);

  //to check if a particualr value is valid for a particular filter field
  const isValidValue = useCallback((field, value) => {
	let values = [];
	switch (field) {
	  case 'productRange':
		values = getProductOptions('productRange');
		break;
	  case 'propertyType':
		values = getPropertyTypeOptions();
		break;
	  case 'productType':
		values = getProductOptions('productType');
		break;
	  case 'ltv':
		values = getLTVOptions();
		break;
	  case 'sortBy':
		values = ['propertytype','producttype','maxLtv','rate','productFeePerc','minLoan','maxLoan'];	
		break;  
	  case 'loanType':
		values = ['Purchase','Remortgage'];
		break;
	  case 'exLocalAuth':
	  case 'newBuild':
	  case 'adjCommercial':
		values = ['Yes','No'];
		break;
	  default:
		return (typeof value  === 'string');
	}
	
	return values.includes(value);
	
  }, [getProductOptions, getPropertyTypeOptions, getLTVOptions]);
  
  //when the page first loads, check if we have any filter params set
  //e.g. http://localhost:8000/products/?productRange=BBC_Reh_02_Core+product+range&propertyType=HMO&productType=1+Year+Fixed
  useEffect(() => {
	
	//are any default filters set via the backend?
	if (typeof initialFilters === 'object') {
		let newValues1 = { ...defaultFormValues };
		
		for (const [key] of Object.entries(defaultFormValues)) {
			if (initialFilters.hasOwnProperty(key) && initialFilters[key]!=='' && isValidValue(key, initialFilters[key])) {
				newValues1[key] = initialFilters[key];
			}
		}

	    if (newValues1['loanType']!=='' || newValues1['exLocalAuth']!=='' || newValues1['newBuild']!=='' || newValues1['adjCommercial']!=='') {
		  setAdvancedFilterOpen(true);
	    }
		
		setFormValues(newValues1);
	}

    //check for browser support for URLSearchParams
	else if (window && 'URLSearchParams' in window && window.location.search) {
	  const params = new URLSearchParams(window.location.search);		
	  let newValues = { ...defaultFormValues };
		
	  //loop through the default form values to give us the key to check in the url
	  for (const [key] of Object.entries(defaultFormValues)) {
		//is it set?
		let value = params.get(key);
		//console.log('checking '+key, value);
		if (key==='ltv') {
			value += '%';//Gatsby bug with url-encoded percentage symbol
		}
		if (params.has(key) && value!=='' && isValidValue(key, value) ) {
		  newValues[key] = value;
		}
	  }

	  //fix for sortReverse which is a boolean
	  if ( params.has('sortReverse') ) {
		  newValues['sortReverse'] = params.get('sortReverse')==='true';
	  }
	  if (params.has('loanType') || params.has('exLocalAuth') || params.has('newBuild') || params.has('adjCommercial')) {
		setAdvancedFilterOpen(true);
	  }

	  setFormValues(newValues);
	}

  }, [initialFilters, defaultFormValues, isValidValue]);
  
  //when the filter values change filter the products
  useEffect(() => {
	//console.log('submitting form...', formValues);
	resetPagination();
	submitForm(formValues);
  }, [submitForm, formValues, resetPagination]);

  /**
   * Print the current screen
   */
  const print = function() {
	if (window) {
		window.print();
	}
  }

  return(
	  <form onSubmit={handleSubmit} className="filter">
		{
		  !hideFilter
		  ? <div className="bg">
				<div className="internal">
					<button className="plain filter-button" type="button" onClick={toggleFilter}>
						<h2><FilterIcon />Filter products (<span>{numMatches}</span>)
						  {
						    filterOpen
						    ? <span className="cancel">Cancel</span>
						    : null
						  }
						</h2>
					</button>
				</div>
				<Collapse isOpen={filterOpen}>
					<div className="top">
						<div className="internal">
							<p>
								<input type="text" name="search" className="text" placeholder="Search product name or code" value={formValues.search} onChange={handleChange} />
							</p>
						</div>
					</div>
					<div className="internal">
						<div className="middle">
							<div className="pulldown">
								<Select 
									options={makePulldownFromArray(getPropertyTypeOptions())} 
	    							placeholder={"Property type"}
									className="react-select-container"
									classNamePrefix="react-select"
									components={{
									  IndicatorSeparator: () => null
									}}
									value={getSelectValue('propertyType')}
	        						onChange={(option) => handleSelectChange('propertyType', option.value)}
								/>
							</div>
							<div className="pulldown">
								<Select 
									options={makePulldownFromArray(getProductOptions('productType'))} 
	    							placeholder={"Product type"}
									className="react-select-container"
									classNamePrefix="react-select"
									components={{
									  IndicatorSeparator: () => null
									}}
									value={getSelectValue('productType')}
	        						onChange={(option) => handleSelectChange('productType', option.value)}
								/>
							</div>
							<div className="pulldown">
								<Select 
									options={makePulldownFromArray(getLTVOptions())} 
	    							placeholder={"Maximum LTV"}
									className="react-select-container"
									classNamePrefix="react-select"
									components={{
									  IndicatorSeparator: () => null
									}}
									value={getSelectValue('ltv')}
	        						onChange={(option) => handleSelectChange('ltv', option.value)}
								/>
							</div>
							<div className="pulldown">
								<Select 
									options={makePulldownFromArray(getProductOptions('productRange'))} 
	    							placeholder={"Product range"}
									/*menuIsOpen={true}*/
									className="react-select-container"
									classNamePrefix="react-select"
									components={{
									  IndicatorSeparator: () => null
									}}
									value={getSelectValue('productRange')}
	        						onChange={(option) => handleSelectChange('productRange', option.value)}
								/>
							</div>
						</div>
						<Collapse isOpen={advancedFilterOpen}>	
							<div className="advanced">
								<RadioGroup label="The applicant wants to" groupname="loanType" options={loanTypeOptions} value={formValues.loanType} onChange={handleChange} />
								<RadioGroup label="Is it an ex local authority property?" groupname="exLocalAuth" options={['Yes','No']} value={formValues.exLocalAuth} onChange={handleChange} />
								<RadioGroup label="Is it a new build property?" groupname="newBuild" options={['Yes','No']} value={formValues.newBuild} onChange={handleChange} />
								<RadioGroup label="Is it above or adjacent to commercial premises?" groupname="adjCommercial" options={['Yes','No']} value={formValues.adjCommercial} onChange={handleChange} />
							</div>
						</Collapse>
						<div className="bottom">
							<p className="advanced-button"><button className={advancedFilterOpen ? 'plain active' : 'plain'} type="button" onClick={toggleAdvancedFilter}>Advanced filters<ChevronIcon /></button></p>
							<p className="buttons">
								<button type="button" onClick={clearForm} className="button solid white small">Clear all</button>
							</p>
						</div>
					</div>
				</Collapse>
			</div>
		  : null
		}
		<div className="results-header internal">
			<p className="product-count">{numMatches} product{numMatches===1 ? '' : 's'}</p>
			<p className="print-search-results"><button type="button" onClick={print} className="plain">Download search results<DownloadIcon /></button></p>							
			<div className="sort">
				Sort by
				<Select 
					options={sortByOptions}
					className="react-select-container"
					classNamePrefix="react-select"
					components={{
					  IndicatorSeparator: () => null
					}}
					value={getSelectValue('sortBy')}
					onChange={(option) => handleSelectChange('sortBy', option.value)}
				/>
				<button className="plain" type="button" aria-label="Reverse" onClick={toggleSortReverse}>
				  {
					formValues.sortReverse
					? <IconSortDesc />
					: <IconSortAsc />
				  }				
				</button>
			</div>
		</div>
	  </form>
  )
}

