import React, { useState } from "react";
import DownloadIcon from "../../assets/svg/inline/ic_download.svg"
import CloseIcon from "../../assets/svg/inline/ic_close.svg"
import { getPropertyType, getMaxLTV, getRate, getReversionRate, getProductFee , getMinLoan, getMaxLoan, getStressRate, getProductCriteria } from './Product';
import { Link } from "gatsby";

export default function ComparisonList({products, clearComparison, setComparisonModalVisible, calculatorLink}) {
	
  const [modalOpen, setModalOpen] = useState(false);

  /**
   * Print the current screen
   */
  const print = function() {
	if (window) {
		window.print();
	}
  }
  const toggleModal = function() {
	const newState = !modalOpen;
	setModalOpen(newState);
	setComparisonModalVisible(newState);
  }
  const calculateColumnWidth = function(columns) {
	return {
		width: 'calc('+(100/columns)+'% - '+(170/columns)+'px)'
	};
  }

  //if there's no items in the list do nothing
  if (products.length===0) {
	return null;
  }
  return(
	<div className="comparison">
	  {
		products.length===1
		? <p>Select another product to compare</p>
		: <>
			{
			  calculatorLink
			  ? <Link to={calculatorLink} state={{ products }} className="button solid purple">Compare affordability</Link>
			  : null
			}
			<button type="button" className="button solid purple" onClick={toggleModal} disabled={products.length>4}>Compare attributes</button>
		</>
	  }
	  <button type="button" className="button line black" onClick={clearComparison}>Cancel</button>
	  
	  {
		products.length>1
		? <p className="counter">{products.length} of 6</p>
		: ''
	  }
	  {
		modalOpen 
		? <div className="modal">
			<h2>Compare {products.length} of 6 products</h2>
			<button className="plain close" aria-label="close" onClick={toggleModal}><CloseIcon /></button>
			<div className="bottom">
				<p className="print-comparison-list"><button type="button" onClick={print} className="plain">Download comparison<DownloadIcon /></button></p>
				<table>
					<tbody>
						<tr>
							<th>Product name</th>
							{products.map((product, i) => (
							  <td key={`product${i}`} className="name" style={calculateColumnWidth(products.length)}>
								<span>{product.productName}</span>
								{product.productTags?.length>0 && (
								  <p className="product-tags">
									{product.productTags.map((tag, j) => (					    
								  	  <span key={`tag${j}`} className="product-tag">{tag}</span>
									))}
								  </p>
								)}
							  </td>
					    	))}	
						</tr>
						<tr>
							<th>Product code</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{product.productCode}</td>
					    	))}	
						</tr>
						<tr>
							<th>Property type</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{getPropertyType(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Product type</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{product.productType}</td>
					    	))}	
						</tr>
						<tr>
							<th>Max LTV</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{getMaxLTV(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Rate</th>
							{products.map((product, i) => (
								<td key={`product${i}`} dangerouslySetInnerHTML={getRate(product)} />
					    	))}	
						</tr>
						<tr>
							<th>Reversion rate</th>
							{products.map((product, i) => (
								<td key={`product${i}`} dangerouslySetInnerHTML={getReversionRate(product)} />
					    	))}	
						</tr>
						<tr>
							<th>Product fee</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{getProductFee(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Min loan</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>&pound;{getMinLoan(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Max loan</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>&pound;{getMaxLoan(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Stress Rate</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{getStressRate(product)}</td>
					    	))}	
						</tr>
						<tr>
							<th>Product criteria</th>
							{products.map((product, i) => (
								<td key={`product${i}`}>{getProductCriteria(product)}</td>
					    	))}	
						</tr>
					</tbody>
				</table>
		 	 </div>
		  </div>
		: null
	  }	
	</div>
  )
}


